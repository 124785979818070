html {
	height: 100%;
}

body {
  text-align: center;
  width: 100%;
  height: 100%;
  background: #f1f1f1;
}

body, div {
  margin:0px;
  padding:0px;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Lucida Grande,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,'.SFNSDisplay-Regular',Heiti SC,Microsoft Yahei;
  color: #4d4d4d;
}

li {
	margin-top:5px;
}

.content-wrapper {
    display:inline-block;
    max-width: 1200px;
    background: white;
    min-height: 100%;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2);
}

.horizontal-spring {
  white-space:pre-wrap;
  visibility: hidden;
}

/* https://css-tricks.com/snippets/css/force-vertical-scrollbar/ */
html {
  overflow-y: scroll;
}

.sidebar {

  float:right;
  right:0;
  position:absolute;
}

.footer {
  border-top:2px solid grey;
  width: 70%;
  /* max-width:500px; */
  display:inline-block;
  margin:10px;
  margin-left: 200px;
  margin-top: 30px;
}

.footer a img {
  max-height:30px;
  max-width:50px;
  vertical-align: middle;
}
.footer a {
  display: inline-block;
  padding:10px;
}
.bio {
	/* max-width:500px; */
	text-align: left;
	/* display:inline-block; */
	margin:20px;
	line-height: 1.25;
	/* min-width: 500px; */
	margin-left: 200px;
	/* max-width: 895px; */
	padding-right: 20px;
}

.name, .subtitle {display:block;text-align: center;}
.name {
	font-weight: 400;
  margin-bottom: 10px;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Lucida Grande,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,'.SFNSDisplay-Regular',Heiti SC,Microsoft Yahei;
  font-size: 24px;
}
.subtitle {
	line-height: 24px;
	font-weight: 400;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Lucida Grande,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,'.SFNSDisplay-Regular',Heiti SC,Microsoft Yahei;
	font-size: 18px;
	display: block;
	margin-left: 200px;
	margin-top: -40px;
	margin-right: 10px;
	/* font-style: italic; */
	/* text-decoration: underline; */
}
.photo {
	width: 100%;
	height: 100%;
	background-color: transparent;
}
.photo img {
    border-radius: 23%;
		height:128px;
		width:128px;
}

.photo-frame {
	/* top: -18px; */
	width: 128px;
	left: 15px;
	height: 128px;
	box-sizing: border-box;
	background-clip: content-box;
	border: 0 solid transparent;
	border-radius: 23%;
	box-shadow: inset 0 1.5px 3px 0 rgba(0,0,0,.15), 0 1.5px 3px 0 rgba(0,0,0,.15);
	background-color: rgba(243,246,248,.94);
	border: 4px solid #fff;
	box-sizing: content-box;
	margin: auto;
	position: relative;
	float:left;
	bottom: -28px;
}

.header {
	background:url(header.jpg);
	background-position: center;
	background-size:cover;
	width:100%;
	height: 140px;
	box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 2px 3px rgba(0,0,0,.2);
	margin-bottom:75px;
	vertical-align: text-bottom;
	position: relative;
	/* overflow: hidden; */
}

@media print {
	.header {
		margin-bottom:0px;
		height: 20px;
	}
}

.title-tags-fragment{
	display: inline-block;
}

.avatar {
	width: 100px;
	margin: 10px;
	display: block;
}

.menu {
	float:left;
	position: absolute;
    width: 150px;
    margin-top: -40px;
}


a:link, a:visited {
  color:#4d4d4d;
  font-weight: bold;
  text-decoration: none;
  font-style: italic;
}
a:hover {
  cursor:pointer;
  text-decoration: underline;
}
.menu-entry {
	text-align:right;
	font-weight: 500;
    font-size: 14pt;
	text-transform: capitalize;
	border-radius:2px;
	padding:10px;
	font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Lucida Grande,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,'.SFNSDisplay-Regular',Heiti SC,Microsoft Yahei;
}

.menu-side {
	float:left;

}
.menu-side-right {
	text-align:left;
}

.menu-entry-left {
	float:left;
}


.title-band, .title-path a {
	color:  #2f2f2f;
	font-style: normal;
}

.title-band {
    text-align: center;
    position: absolute;
    bottom: 0;
    height: 40px;
    font-size: 29pt;
    font-weight: bold;
    background: white;
    padding-bottom: 6px;
    opacity: .75;
    margin-left: 144px;
    text-transform: lowercase;
    vertical-align: middle;
    width: calc(100% - 144px);;
}

@media print {

	.title-band {
    	text-transform: initial;
	}
}


@media only screen and  (min-width: 500px) and (max-width: 800px),  print  {
	.magic-spacer {
	  width: 100px;
	  display: inline-block;
	}
}

@media only screen and (max-width: 600px),  print  {
	
	.title-path {
		display: none;
		width: 0px;;
	}
}



@media only screen and (min-width: 800px) {

	.top-menu {
		display: none;
	}
}

@media only screen and (max-width: 800px){

	.top-menu {
		position: sticky;
		top: 0px;
		width: 100%;
		background: #515151;
		z-index: 100;
	}
}

@media only print{

	.top-menu {
		display: none;
	}
}


@media only screen and (max-width: 800px),  print  {

	.bio {
		margin:10px;
		padding:10px;

	}
	.subtitle {
		margin:0px;
		margin-right: 20px;
		margin-left: 20px;
	}

  .footer {
		margin:10px;
		padding:10px;
		width:90%;
  }
	.title-band {margin-right: 10px;font-size: 23pt;/* margin-left: 0px; *//* width: 100%; *//* padding-right: 10px; */}
	.menu {
		display: none;

	}
	.menu-entry {
		padding:5px;
		display:inline-block;
	}
	.menu-entry a {
		color:white;
		text-transform: lowercase;
		padding-left: 2px;
		padding-right: 2px;
		font-size: 10pt;
	}


}

.menu-entry a {
  font-weight: normal;
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Fira Sans,Ubuntu,Oxygen,Oxygen Sans,Cantarell,Droid Sans,Lucida Grande,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Emoji,Segoe UI Symbol,Hiragino Kaku Gothic Pro,Meiryo,Hiragino Sans GB W3,Noto Naskh Arabic,Droid Arabic Naskh,Geeza Pro,Simplified Arabic,Noto Sans Thai,Thonburi,Dokchampa,Droid Sans Thai,Droid Sans Fallback,'.SFNSDisplay-Regular',Heiti SC,Microsoft Yahei;
}

.menu-entry-selected a {
	font-weight: bold;
}


.video, .novideo {
	width:560px;
	height:315px;
}


@media only screen and (max-width: 650px) {
	.video, .novideo {
		width:90%;
	}
}

.novideo {
	border:1px solid darkgrey;
	vertical-align: middle;
}

.code-editor {
	font-family: monospace;
	font-size: 14pt;
	text-align: left;
	padding: 20px;
	padding-left: 146px;
	min-width: 859px;
	white-space: pre;
	line-height: 175%;
	margin-left:10px;
}


.references-notice {
    text-align:center;
    border-top:1px solid grey;
    margin:20px;
    padding:20px;
}

@media print  {
    .menu {
        display:none;
    }

	
	.content-wrapper {
		display:block;
	}

    .contact-band {
      /*
        position:fixed;
        bottom:-40;
        width:100%;
        margin:0px;
        padding:0px;
        */
    }

    .role {
		padding-bottom:-200px;
	}

    .references-notice {
        width:100%;
        /* min-height:200px; */
        vertical-align:middle;
        border:0px;
        margin:0px;
        margin-top:200px;
        padding:0px;
    }
	
}

.banner-note {
    background-color: #fff3c5;
    padding: 10px;
	margin:10px;
	margin-bottom:15px;
    display: inline-block;
    border: 1px solid #ffda4c;
    border-radius: 5px;
}