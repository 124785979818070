body {
  line-height: 1.25;
}

div {
  margin: 0px;
  padding: 0px;
}

h1 {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 5px;
  font-style: italic;
  font-size: 12pt;
}

.contact-band {
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}

.location-band {
  text-align: center;
}


/* part 2 */


.role {
  /* margin-bottom:50px; */
  text-align: left;
  width: 100%;
}

.role-title, .role-span, .role-summary, .role-company {
  page-break-inside: avoid;
}

.role {
  page-break-inside: avoid;
}

.tech-header {
  page-break-before: always;
}

.role-title {
  font-size: 1.5em;
  text-align: left;
  /* font-weight: bold; */
  margin-block-start: 0.83em;
  margin-block-end: 0.3em;
}

.role div {
  text-align: left;
  /* margin-left:10px; */
}

.role-summary {
  font-weight: normal;
  /* white-space: pre-wrap; */
}

.role-company {
  font-size: 1.5em;
  display: block;
  margin-bottom: 25px;
  font-style: italic;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  /*
  text-decoration:underline;
   */
}

.role-span,
.role-summary {
  margin: 5px;
}

.role ul {
  margin: 0px;
  white-space: normal;
}

.role li {
  margin-bottom: 5px;
}

.company-header {
  margin-top: 40px;
  page-break-inside: avoid;
}
.company-description {
  margin-left: 0px;
}

.company-roles {

  border-left: 1px solid #dbdbdb;
  padding-left: 10px;
}

.company {
}


@media only screen and (min-width: 800px),  print  {
  .minor-company {
    display: inline-block;
    max-width: 32%;
    vertical-align: top;
  }
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.role-summary h4 {
  margin-top: 0px;
  margin-bottom: 15px;
}

.role-summary p {
  /* margin:0px; */
}

.markdown {
  white-space: pre;
}

.halflist {

  margin-right: 10px;
}

.halflist>ul {
  padding-left: 5px;
}

.halflist>ul>li {
  list-style-type: none;
  font-style: italic;
}

.halflist>ul>li>ul {
  padding-top: 5px;
}

.halflist>ul>li>ul>li {
  list-style-type: disc;
  font-style: normal;
}

.references-notice {
  text-align: center;
  border-top: 1px solid grey;
  margin-top: 20px;
  padding-top: 20px;
}

.company-duration {
  /* margin-left: 10px; */
  font-size: 12pt;
  vertical-align: middle;
  margin-bottom: 5px;
  /* hack! wish I could just vertical-align this */
  display: inline-block;
}

@media print {
  .photo-frame {
    display: none;
  }
  .header {
    height: 60px;
  }
}

@media only screen and (min-width: 800px),
print {
  .halflist-holder {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

.tech-tag {
  padding: 5px;
  border: 1px solid rgb(193, 193, 193);
  border-radius: 3px;
  margin: 5px;
  vertical-align: top;
}
.tech-tag-expanded {
  display: block;
}
.tech-tag-expanded-header {
  display: block;
}

.tech-tag-collapsed {
  display: inline-block;
}

.tech-tag-expanded-header:hover  {
  text-decoration: underline;
  cursor: pointer;
}
.tech-tag-collapsed:hover{
  background: darkgrey;
  color: white;
  cursor: pointer;
}

.tech-tag-guts {
  background:white;
  margin:0px;
  /* background: #feff9c; */
  border-top:1px solid grey;
  margin-top:5px;
  padding-top:5px;
}

.tech-tag .tech-notes {
  margin:5px;
}

.tech-tag .tech-url {
  margin:5px;
  margin-top:15px;
  margin-bottom:10px;
  display: block;
}



.tech-header {
  font-size: 1.5em;
  display: block;
  margin-bottom: 25px;
  font-style: italic;
  border: 0px;
  /* border-top:1px solid grey; */
  border-bottom: 1px solid grey;
  /* text-align: center; */
  color: grey;
  margin-top: 100px;
}

.tech-category-header {
  font-size: 1.5em;
  text-align: left;
  font-weight: bold;
  margin-block-start: 0.83em;
  margin-block-end: 0.3em;
  margin-bottom: 20px;
  margin-top: 35px;

  color: grey;
}

.tech-category-section{
  page-break-inside: avoid;
}

.appendix-list li {
  line-height: 21pt;
}


